import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/VerificationPage1.css';
import { FaRecycle } from 'react-icons/fa';
import Swal from "sweetalert2"

// Function to generate an arithmetic problem
const generateArithmeticProblem = () => {
  const operations = ['+', '-', '*', '/'];
  const num1 = Math.floor(Math.random() * 10) + 1; // Numbers between 1 and 10
  const num2 = Math.floor(Math.random() * 10) + 1; // Numbers between 1 and 10
  const operation = operations[Math.floor(Math.random() * operations.length)];
  
  let question = '';
  let answer = 0;

  switch (operation) {
    case '+':
      question = `${num1} + ${num2}`;
      answer = num1 + num2;
      break;
    case '-':
      question = `${num1} - ${num2}`;
      answer = num1 - num2;
      break;
    case '*':
      question = `${num1} * ${num2}`;
      answer = num1 * num2;
      break;
    case '/':
      question = `${num1} / ${num2}`;
      answer = num1 / num2;
      break;
    default:
      break;
  }

  return { question, answer: Math.round(answer * 100) / 100 }; // Round to 2 decimal places
};

const VerificationPage2 = () => {
  const [problems, setProblems] = useState([{}, {}]);
  const [userAnswers, setUserAnswers] = useState(['', '']);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const newProblems = [generateArithmeticProblem(), generateArithmeticProblem()];
    setProblems(newProblems);
  }, []);

  const handleChange = (index, e) => {
    const answers = [...userAnswers];
    answers[index] = e.target.value;
    setUserAnswers(answers);
  };

  const handleSubmit = () => {
    const allCorrect = problems.every((problem, index) =>
      parseFloat(userAnswers[index]) === problem.answer
    );

    if (allCorrect) {
      setIsVerified(true);
    } else {
      Swal.fire({icon:"error",text:"One or more answers are incorrect. Please try again."})

    }
  };

  useEffect(() => {
    if (isVerified) {
      navigate('/login'); // Navigate to the next page if verified
    }
  }, [isVerified, navigate]);

  return (
    <div className="verification-page-container">
      <h1 className="verification-page-title">Please Confirm You Are Not a Robot</h1>
      <p className="verification-page-description">
        Confirm you are not a robot by solving the arithmetic problems below.
        This will enable you to access Merit National Bank's online banking channels.
      </p>
      {problems.map((problem, index) => (
        <div key={index} className="verification-page-problem">
          <p className="verification-page-problem-text">Solve the following problem:</p>
          <p className="verification-page-problem-question">{problem.question}</p>
          <input
            type="number"
            step="any"
            value={userAnswers[index]}
            onChange={(e) => handleChange(index, e)}
            placeholder="Enter your answer"
            className="verification-page-input"
          />
        </div>
      ))}
      <button onClick={handleSubmit} className="verification-page-button">
        Proceed
      </button>
      <button onClick={()=>window.location.reload()} className='verification-page-button' style={{
        backgroundColor:"white",
        border:"1px solid blue",
        color:"blue",
        marginTop:"10px"
      }}>Try another Question</button>
    </div>
  );
};

export default VerificationPage2;
