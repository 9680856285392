import React, { useContext, useEffect, useState } from 'react';
import '../CSS/About.css';
import abImg3 from "../Images/abImg3.png";
import abImg4 from "../Images/abImg4.png";
import { FaHourglassHalf, FaEarthAmericas } from "react-icons/fa6";
import T1 from "../Images/T1.jpeg";
import T2 from "../Images/T2.jpeg";
import T3 from "../Images/T3.jpeg";
import T4 from "../Images/T4.jpeg";
import T5 from "../Images/T5.jpeg";
import T6 from "../Images/T6.jpeg";
import T7 from "../Images/T7.jpeg";
import T8 from "../Images/T8A.jpeg";
import T9 from "../Images/T9.jpeg";
import T10 from "../Images/T10.jpeg";
import { Context } from './Context';

const About = () => {
  const teamMembers = [
    { name: 'Victory Cleveland', position: 'Executive Director of Payments', src: T1 },
    { name: 'Jane cathrall', position: 'Executive Director of People’s', src: T2 },
    { name: 'Andrew Bailey', position: 'Governor of Banks England', src: T3 },
    { name: 'Michelle W. Bowman', position: 'Washington DC Banks Board member', src: T4 },
    { name: 'Andrew Bailey', position: 'CEO', src: T5 },
    { name: 'Michael S. Barr', position: 'Washington DC Banks board', src: T6 },
    { name: 'Lisa D.cook', position: 'Washington D.C Bank boards', src: T7 },
    { name: 'Mr. Basel AL-Haroon', position: 'Kuwait board of Directors chairman', src: T8 },
    { name: 'Elvira Nabiullina', position: 'Governor of the Bank of Russia', src: T10 },
    { name: 'Mr khaled Ibrahim Humaidan', position: 'Vice', src: T9 }
  ];

  
  // const {scrollStatus,setScrollStatus}=useContext(Context)

  // useEffect(() => {
  //   const handleIntersection = (entries) => {
  //     entries.forEach(entry => {
  //       if (entry.target.id === "about") {
  //         if (entry.isIntersecting) {
  //           setScrollStatus("about");
  //         } else {
  //           setScrollStatus("");
  //         }
  //       }
  //     });
  //   };

  //   const options = {
  //     threshold: 0.1 // Adjust threshold as needed
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, options);
  //   const target = document.getElementById("about");

  //   if (target) {
  //     observer.observe(target);
  //   }

  //   return () => {
  //     if (target) {
  //       observer.unobserve(target);
  //     }
  //   };
  // }, [setScrollStatus]);



  useEffect(() => {
    const features = document.querySelectorAll('.about-section');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
          observer.unobserve(entry.target); // Stop observing once it's in view
        }
      });
    }, { threshold: 0.1 });

    features.forEach(feature => {
      observer.observe(feature);
    });

    return () => {
      features.forEach(feature => observer.unobserve(feature));
    };
  }, []);


  return (
    <div className="merit-about" id="about">
      <section className="about-section who-we-are animate__animated animate__bounce">
        <h2>OUR IDENTITY AND PURPOSE</h2>
        <div className='who-we-are-child'>
          <p>
            Merit National Bank is a leading financial institution committed to providing unparalleled financial services. With a rich history and a focus on innovation, we empower our clients to achieve their financial dreams and aspirations.
          </p>
          <img src={abImg4} alt="Who We Are" />
        </div>
      </section>
      <section className="about-section what-we-do">
        <h2>HOW WE SERVE YOU</h2>
        <div className='what-we-do-child'>
          <p>
            We offer a wide range of financial services including personal and business banking, loans, credit cards, and investment services. Our goal is to provide innovative solutions that meet the unique needs of our clients.
          </p>
          <img src={abImg3} alt="What We Do" />
        </div>
      </section>
      <section className="about-section our-vision">
        <h2>Our Vision</h2>
        <FaEarthAmericas style={{ fontSize: "4rem", color: "rgba(0,0,255,0.3)" }} />
        <p>
          Our vision is to be the most trusted and innovative financial institution, empowering individuals and businesses to achieve their goals and build a prosperous future.
        </p>
      </section>
      <section className="about-section our-mission">
        <h2>Our Mission</h2>
        <FaHourglassHalf style={{ fontSize: "4rem", color: "rgba(0,0,255,0.3)" }} />
        <p>
          Our mission is to provide exceptional financial services that empower our clients to reach their full potential. We are dedicated to fostering trust, innovation, and excellence in everything we do.
        </p>
      </section>
      <section className="about-section our-team">
        <h2>Share Holders</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <img src={member.src} alt={member.name} className="team-image" style={{ borderRadius: "50%" }} />
              <h3 style={{ color: "rgba(0,0,255,0.5)" }}>{member.name}</h3>
              <p>{member.position}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
