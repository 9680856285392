// import React from 'react';
// import { FaShieldAlt, FaHandsHelping, FaChartLine } from 'react-icons/fa';
// import "../CSS/Features.css"; // Make sure to create and style this CSS file

// const Features = () => {
//   return (
//     <div className="features-container">
//       <div className="feature">
//         <FaShieldAlt className="feature-icon" />
//         <h3>Trust</h3>
//         <p>Our legacy of trust ensures your financial security and peace of mind.</p>
//       </div>
//       <div className="feature">
//         <FaHandsHelping className="feature-icon" />
//         <h3>Excellence</h3>
//         <p>We are dedicated to providing excellent services that empower your financial journey.</p>
//       </div>
//       <div className="feature">
//         <FaChartLine className="feature-icon" />
//         <h3>Prosperity</h3>
//         <p>Our innovative solutions guide you towards a prosperous future.</p>
//       </div>
//     </div>
//   );
// };

// export default Features;




import React, { useEffect } from 'react';
import { FaShieldAlt, FaHandsHelping, FaChartLine } from 'react-icons/fa';
import "../CSS/Features.css";

const Features = () => {

  useEffect(() => {
    const features = document.querySelectorAll('.feature');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
          observer.unobserve(entry.target); // Stop observing once it's in view
        }
      });
    }, { threshold: 0.1 });

    features.forEach(feature => {
      observer.observe(feature);
    });

    return () => {
      features.forEach(feature => observer.unobserve(feature));
    };
  }, []);

  return (
    <div className="features-container">
      <div className="feature">
        <FaShieldAlt className="feature-icon" />
        <h3>Trust</h3>
        <p>Our legacy of trust ensures your financial security and peace of mind.</p>
      </div>
      <div className="feature">
        <FaHandsHelping className="feature-icon" />
        <h3>Excellence</h3>
        <p>We are dedicated to providing excellent services that empower your financial journey.</p>
      </div>
      <div className="feature">
        <FaChartLine className="feature-icon" />
        <h3>Prosperity</h3>
        <p>Our innovative solutions guide you towards a prosperous future.</p>
      </div>
    </div>
  );
};

export default Features;

