// import React, { useContext, useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import "../CSS/ManualCredit.css";
// import { Context } from './Context';

// const ManualCredit = () => {
//     const { setOpenCreditUserBalanceUi } = useContext(Context);
//     const [accountNumber, setAccountNumber] = useState('');
//     const [accountName, setAccountName] = useState('');
//     const [amount, setAmount] = useState('');
//     const [isSubmitting, setIsSubmitting] = useState(false);

//     useEffect(() => {
//         if (accountNumber.length === 10) {
//             Swal.fire({
//                 title: 'Fetching account details...',
//                 allowOutsideClick: false,
//                 didOpen: () => {
//                     Swal.showLoading();
//                 }
//             });

//             fetchAccountName(accountNumber);
//         }
//     }, [accountNumber]);

//     const fetchAccountName = async (accountNumber) => {
//         try {
//             const response = await fetch(`https://meritnationalbank.com/api/manual_credit.php?account_number=${accountNumber}`, {
//                 method: 'GET'
//             });
//             const data = await response.json();

//             Swal.close();

//             if (data.success) {
//                 setAccountName(`${data.first_name} ${data.last_name}`);
//             } else {
//                 Swal.fire('Error!', data.message);
//             }
//         } catch (error) {
//             Swal.fire('Error!', 'Unable to fetch account details.');
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!accountNumber || !amount) {
//             Swal.fire('Error!', 'Please enter both the account number and amount.');
//             return;
//         }

//         setIsSubmitting(true);
//         Swal.fire({
//             title: 'Crediting...',
//             allowOutsideClick: false,
//             didOpen: () => {
//                 Swal.showLoading();
//             }
//         });

//         try {
//             const response = await fetch('https://meritnationalbank.com/api/manual_credit.php', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                 },
//                 body: new URLSearchParams({
//                     account_number: accountNumber,
//                     amount: amount,
//                 }),
//             });

//             const data = await response.json();

//             Swal.close();

//             if (data.success) {
//                 Swal.fire('Success!', data.message);
//                 setAccountNumber('');
//                 setAmount('');
//                 setAccountName('');
//                 setOpenCreditUserBalanceUi(false);
//             } else {
//                 Swal.fire('Error!', data.message);
//             }
//         } catch (error) {
//             Swal.fire('Error!', 'Something went wrong. Please try again.');
//         }

//         setIsSubmitting(false);
//     };

//     return (
//         <div className='ManualCreditWrap'>
//             <div className="ManualCredit">
//                 <h2>Credit User Account Balance</h2>
//                 <form onSubmit={handleSubmit}>
//                     <input
//                         type="text"
//                         placeholder="Account Number"
//                         value={accountNumber}
//                         onChange={(e) => setAccountNumber(e.target.value)}
//                         disabled={isSubmitting}
//                         required
//                     />
//                     {accountName && (
//                         <p style={{ fontStyle: 'italic', color: 'blue' }}>Account Name: {accountName}</p>
//                     )}
//                     <input
//                         type="text"
//                         placeholder="Amount"
//                         value={amount}
//                         onChange={(e) => setAmount(e.target.value)}
//                         disabled={isSubmitting}
//                         required
//                     />
//                     <div className='ManualCreditButtonWrap'>
//                         <button
//                             onClick={() => setOpenCreditUserBalanceUi(false)}
//                             style={{ color: "blue", backgroundColor: "white", border: "1px solid blue" }}
//                         >
//                             Cancel
//                         </button>
//                         <button type="submit" disabled={isSubmitting}>
//                             {isSubmitting ? 'Submitting...' : 'Credit Account'}
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default ManualCredit;

import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import "../CSS/ManualCredit.css";
import { Context } from './Context';

const ManualCredit = () => {
    const { setOpenCreditUserBalanceUi } = useContext(Context);
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountCurrency, setAccountCurrency] = useState('');
    const [amount, setAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (accountNumber.length === 10) {
            Swal.fire({
                title: 'Fetching account details...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            fetchAccountDetails(accountNumber);
        }else{
            setAccountName("")
            setAccountCurrency("")
        }
    }, [accountNumber]);

    const fetchAccountDetails = async (accountNumber) => {
        try {
            const response = await fetch(`https://meritnationalbank.com/api/manual_credit.php?account_number=${accountNumber}`, {
                method: 'GET'
            });
            const data = await response.json();

            Swal.close();

            if (data.success) {
                setAccountName(`${data.first_name} ${data.last_name}`);
                setAccountCurrency(data.account_currency);
            } else {
                Swal.fire('Error!', data.message);
                setAccountCurrency("")
                setAccountName("")
            }
        } catch (error) {
            Swal.fire('Error!', 'Unable to fetch account details.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!accountNumber || !amount) {
            Swal.fire('Error!', 'Please enter both the account number and amount.');
            return;
        }

        setIsSubmitting(true);
        Swal.fire({
            title: 'Crediting...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await fetch('https://meritnationalbank.com/api/manual_credit.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    account_number: accountNumber,
                    amount: amount,
                }),
            });

            const data = await response.json();

            Swal.close();

            if (data.success) {
                Swal.fire('Success!', data.message);
                setAccountNumber('');
                setAmount('');
                setAccountName('');
                setAccountCurrency('');
                setOpenCreditUserBalanceUi(false);
            } else {
                Swal.fire('Error!', data.message);
            }
        } catch (error) {
            Swal.fire('Error!', 'Something went wrong. Please try again.');
        }

        setIsSubmitting(false);
    };

    return (
        <div className='ManualCreditWrap'>
            <div className="ManualCredit">
                <h2>Credit User Account Balance</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        disabled={isSubmitting}
                        required
                    />
                    {accountName && (
                        <p style={{ fontStyle: 'italic', color: 'blue' }}>Account Name: {accountName}</p>
                    )}
                    {accountCurrency && (
                        <p style={{ fontStyle: 'italic', color: 'green' }}>Account Currency: {accountCurrency}</p>
                    )}
                    <input
                        type="text"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        disabled={isSubmitting}
                        required
                    />
                    <div className='ManualCreditButtonWrap'>
                        <button
                            onClick={() => setOpenCreditUserBalanceUi(false)}
                            style={{ color: "blue", backgroundColor: "white", border: "1px solid blue" }}
                        >
                            Cancel
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Credit Account'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ManualCredit;
