

// import React, { useContext, useEffect, useState } from 'react';
// import '../CSS/AllUsers.css';
// import { Context } from './Context';
// import Swal from 'sweetalert2';

// function AllUsers() {
//     const [users, setUsers] = useState([]);
//     const { setOpenAllUsersModal } = useContext(Context);
//     const [error, setError] = useState(null);
//     const [editingUser, setEditingUser] = useState(null);  // Track the user being edited
//     const [newBalance, setNewBalance] = useState('');  // Track the new balance value


//     const fetchAllUsers = ()=>{

//         const loadingAlert = Swal.fire({ title: "Fetching Users..." });
//         Swal.showLoading();

//         fetch('https://meritnationalbank.com/api/fetch-all-users.php')
//             .then(response => response.json())
//             .then(data => {
//                 if (data.success) {
//                     setUsers(data.users);
//                 } else {
//                     setError(data.message);
//                 }
//                 loadingAlert.close();
//             })
//             .catch(err => {
//                 setError('Error fetching users');
//                 loadingAlert.close();
//             });
//     }

//     useEffect(()=>{
//         fetchAllUsers()
//     },[])


//     const handleSuspend = (accountNumber) => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "Do you really want to suspend this user?",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonText: 'Yes, suspend',
//             cancelButtonText: 'No, cancel!',
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 const loadingAlert = Swal.fire({ title: "Please wait..." });
//                 Swal.showLoading();

//                 fetch('https://meritnationalbank.com/api/suspend-user.php', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ account_number: accountNumber }),
//                 })
//                     .then(response => response.json())
//                     .then(data => {
//                         if (data.success) {
//                             setUsers(users.map(user =>
//                                 user.account_number === accountNumber ? { ...user, suspended: "1" } : user
//                             ));
//                             Swal.fire('Suspended!', data.message, 'success');
//                         } else {
//                             Swal.fire('Error', data.message, 'error');
//                         }
//                         loadingAlert.close();
//                     });
//             }
//         });
//     };

//     const handleUnsuspend = (accountNumber) => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "Do you really want to unsuspend this user?",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonText: 'Yes, unsuspend',
//             cancelButtonText: 'No, cancel!',
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 const loadingAlert = Swal.fire({ title: "Please wait..." });
//                 Swal.showLoading();

//                 fetch('https://meritnationalbank.com/api/unsuspend-user.php', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ account_number: accountNumber }),
//                 })
//                     .then(response => response.json())
//                     .then(data => {
//                         if (data.success) {
//                             setUsers(users.map(user =>
//                                 user.account_number === accountNumber ? { ...user, suspended: "0" } : user
//                             ));
//                             Swal.fire('Unsuspended!', data.message, 'success');
//                         } else {
//                             Swal.fire('Error', data.message, 'error');
//                         }
//                         loadingAlert.close();
//                     });
//             }
//         });
//     };

//     const handleEditBalance = (user) => {
//         setEditingUser(user);
//         setNewBalance(user.balance); // Set the current balance in the input field
//     };

//     const handleSaveBalance = () => {
//         if (!newBalance || isNaN(newBalance)) {
//             Swal.fire('Error', 'Please enter a valid balance', 'error');
//             return;
//         }

//         const loadingAlert = Swal.fire({ title: "Updating Balance..." });
//         Swal.showLoading();

//         fetch('https://meritnationalbank.com/api/edit-balance.php', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ account_number: editingUser.account_number, new_balance: newBalance }),
//         })
//             .then(response => response.json())
//             .then(data => {
//                 if (data.success) {
//                     setUsers(users.map(user =>
//                         user.account_number === editingUser.account_number ? { ...user, balance: newBalance } : user
//                     ));
//                     Swal.fire('Success', 'Balance updated successfully', 'success');
//                     setEditingUser(null);  // Close the editing form
//                 } else {
//                     Swal.fire('Error', data.message, 'error');
//                 }
//                 loadingAlert.close();
//             })
//             .catch(() => {
//                 Swal.fire('Error', 'Failed to update balance', 'error');
//                 loadingAlert.close();
//             });
//     };


// const [collectAccountNumber,setCollectAccountNumber]=useState("")

// useEffect(()=>{
//     if(collectAccountNumber.length===10){
//         const updateArray = users.filter((e)=>e.account_number==collectAccountNumber)
//     setUsers(updateArray)
//     }else{

//     }
// },[collectAccountNumber])

//     return (
//         <div className='AllUsersWrap'>
//             <div className="users-table-container">
//                 <h1 className="users-table-title">User Details</h1>
//                 <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
//                     <p style={{color:"blue",fontWeight:"bold"}}>Filter user by 10 digit account number</p>
//                     <input style={{padding:"10px",outline:"none",borderRadius:"5px",border:"none"}} onChange={(e)=>setCollectAccountNumber(e.target.value)} placeholder='Enter user 10 digit account number'/>


//                     </div>
//                 <div className="users-grid">
                    
//                     {users.map(user => (
//                         <div className="user-cardA" key={user.account_number}>
//                             <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Name:</strong> {user.first_name} {user.last_name}</p>
//                             <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Account Number:</strong> {user.account_number} {user.account_type} {user.account_currency}</p>
//                             <p><strong style={{color:"rgba(0,0,255,0.7)"}} >Account Balance:</strong> {user.balance}</p>
//                             <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Country:</strong> {user.country}</p>
//                             <p style={{color:user.suspended==="1"?"red":"green"}}><strong >Suspended:</strong> {user.suspended === "1" ? 'Yes' : 'No'}</p>

//                             <div className="user-actions">
//                                 {user.suspended === "1" ? (
//                                     <button onClick={() => handleUnsuspend(user.account_number)}>Unsuspend</button>
//                                 ) : (
//                                     <button onClick={() => handleSuspend(user.account_number)}>Suspend</button>
//                                 )}
//                                 <button onClick={() => handleEditBalance(user)} style={{marginLeft:"10px",cursor:"pointer"}}>Edit Balance</button>
//                             </div>

//                             {editingUser && editingUser.account_number === user.account_number && (
//                                 <div className="edit-balance-form" style={{marginTop:'10px',display:"flex",gap:"10px"}}>
//                                     <input
//                                         type="number"
//                                         value={newBalance}
//                                         onChange={(e) => setNewBalance(e.target.value)}
//                                         placeholder="New balance"
//                                         style={{padding:"5px",outline:"none"}}
//                                     />
//                                     <button onClick={handleSaveBalance} 
//                                     style={{padding:"5px",
//                                         borderRadius:"5px",
//                                         border:"none",
//                                         cursor:"pointer",
//                                         backgroundColor:"rgba(0,0,255,0.7)",
//                                         color:"white",
//                                     }}>Save</button>
//                                     <button onClick={() => setEditingUser(null)} 
//                                         style={{padding:"5px",
//                                             borderRadius:"5px",
//                                             border:"none",
//                                             cursor:"pointer",
//                                             backgroundColor:"white",
//                                             color:"blue",
//                                             border:"1px solid blue"
//                                         }}>Cancel</button>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 </div>
//                 <button onClick={() => setOpenAllUsersModal(false)} className='AllUserCancelButton'>Close</button>
//             </div>
//         </div>
//     );
// }

// export default AllUsers;

import React, { useContext, useEffect, useState } from 'react';
import '../CSS/AllUsers.css';
import { Context } from './Context';
import Swal from 'sweetalert2';

function AllUsers() {
    const [users, setUsers] = useState([]); // Original users data
    const [filteredUsers, setFilteredUsers] = useState([]); // Filtered data for search
    const { setOpenAllUsersModal } = useContext(Context);
    const [error, setError] = useState(null);
    const [editingUser, setEditingUser] = useState(null);  // Track the user being edited
    const [newBalance, setNewBalance] = useState('');  // Track the new balance value
    const [collectAccountNumber, setCollectAccountNumber] = useState(""); // For searching by account number

    // Fetch all users from the API
    const fetchAllUsers = () => {
        const loadingAlert = Swal.fire({ title: "Fetching Users..." });
        Swal.showLoading();

        fetch('https://meritnationalbank.com/api/fetch-all-users.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setUsers(data.users);
                    setFilteredUsers(data.users); // Initialize filteredUsers with full data
                } else {
                    setError(data.message);
                }
                loadingAlert.close();
            })
            .catch(err => {
                setError('Error fetching users');
                loadingAlert.close();
            });
    };

    // Fetch all users on component mount
    useEffect(() => {
        fetchAllUsers();
    }, []);

    // Filter logic for account number
    useEffect(() => {
        if (collectAccountNumber.length === 10) {
            const filtered = users.filter(user => user.account_number === collectAccountNumber);
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users); // Reset to all users if the input is not valid or cleared
        }
    }, [collectAccountNumber, users]);

    // Handle suspension of users
    const handleSuspend = (accountNumber) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to suspend this user?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, suspend',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const loadingAlert = Swal.fire({ title: "Please wait..." });
                Swal.showLoading();

                fetch('https://meritnationalbank.com/api/suspend-user.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ account_number: accountNumber }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            setUsers(users.map(user =>
                                user.account_number === accountNumber ? { ...user, suspended: "1" } : user
                            ));
                            Swal.fire('Suspended!', data.message, 'success');
                        } else {
                            Swal.fire('Error', data.message, 'error');
                        }
                        loadingAlert.close();
                    });
            }
        });
    };

    // Handle unsuspension of users
    const handleUnsuspend = (accountNumber) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to unsuspend this user?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unsuspend',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const loadingAlert = Swal.fire({ title: "Please wait..." });
                Swal.showLoading();

                fetch('https://meritnationalbank.com/api/unsuspend-user.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ account_number: accountNumber }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            setUsers(users.map(user =>
                                user.account_number === accountNumber ? { ...user, suspended: "0" } : user
                            ));
                            Swal.fire('Unsuspended!', data.message, 'success');
                        } else {
                            Swal.fire('Error', data.message, 'error');
                        }
                        loadingAlert.close();
                    });
            }
        });
    };

    // Handle balance editing
    const handleEditBalance = (user) => {
        setEditingUser(user);
        setNewBalance(user.balance); // Set the current balance in the input field
    };

    // Save new balance
    // const handleSaveBalance = () => {
    //     if (!newBalance || isNaN(newBalance)) {
    //         Swal.fire('Error', 'Please enter a valid balance', 'error');
    //         return;
    //     }

    //     const loadingAlert = Swal.fire({ title: "Updating Balance..." });
    //     Swal.showLoading();

    //     fetch('https://meritnationalbank.com/api/edit-balance.php', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ account_number: editingUser.account_number, new_balance: newBalance }),
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.success) {
    //                 setUsers(users.map(user =>
    //                     user.account_number === editingUser.account_number ? { ...user, balance: newBalance } : user
    //                 ));
    //                 Swal.fire('Success', 'Balance updated successfully', 'success');
    //                 setEditingUser(null);  // Close the editing form
    //             } else {
    //                 Swal.fire('Error', data.message, 'error');
    //             }
    //             loadingAlert.close();
    //         })
    //         .catch(() => {
    //             Swal.fire('Error', 'Failed to update balance', 'error');
    //             loadingAlert.close();
    //         });
    // };

    const handleSaveBalance = () => {
        if (!newBalance || isNaN(newBalance)) {
            Swal.fire('Error', 'Please enter a valid balance', 'error');
            return;
        }
    
        // Show confirmation dialog before proceeding
        Swal.fire({
            // title: 'Are you sure?',
            text: `You are about to update the balance to ${newBalance}. Do you want to proceed?`,
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const loadingAlert = Swal.fire({ title: "Updating Balance..." });
                Swal.showLoading();
    
                fetch('https://meritnationalbank.com/api/edit-balance.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ account_number: editingUser.account_number, new_balance: newBalance }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            setUsers(users.map(user =>
                                user.account_number === editingUser.account_number ? { ...user, balance: newBalance } : user
                            ));
                            Swal.fire('Success', 'Balance updated successfully', 'success');
                            setEditingUser(null);  // Close the editing form
                        } else {
                            Swal.fire('Error', data.message, 'error');
                        }
                        loadingAlert.close();
                    })
                    .catch(() => {
                        Swal.fire('Error', 'Failed to update balance', 'error');
                        loadingAlert.close();
                    });
            }
        });
    };
    

    return (
        <div className='AllUsersWrap'>
            <div className="users-table-container">
                <h1 className="users-table-title">User Details</h1>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <p style={{ color: "blue", fontWeight: "bold" }}>Filter user by 10 digit account number</p>
                    <input
                        style={{ padding: "10px", outline: "none", borderRadius: "5px", border: "none" }}
                        onChange={(e) => setCollectAccountNumber(e.target.value)}
                        value={collectAccountNumber}
                        placeholder='Enter user 10 digit account number'
                    />
                </div>
                <div className="users-grid">
                    {filteredUsers.map(user => (
                        <div className="user-cardA" key={user.account_number}>
                            <p><strong style={{ color: "rgba(0,0,255,0.7)" }}>Name:</strong> {user.first_name} {user.last_name}</p>
                            <p><strong style={{ color: "rgba(0,0,255,0.7)" }}>Account Number:</strong> {user.account_number} {user.account_type} {user.account_currency}</p>
                            <p><strong style={{ color: "rgba(0,0,255,0.7)" }}>Account Balance:</strong> {user.balance}</p>
                            <p><strong style={{ color: "rgba(0,0,255,0.7)" }}>Country:</strong> {user.country}</p>
                            <p style={{ color: user.suspended === "1" ? "red" : "green" }}>
                                <strong>Suspended:</strong> {user.suspended === "1" ? 'Yes' : 'No'}
                            </p>
                            <div className="user-actions">
                                {user.suspended === "1" ? (
                                    <button onClick={() => handleUnsuspend(user.account_number)}>Unsuspend</button>
                                ) : (
                                    <button onClick={() => handleSuspend(user.account_number)}>Suspend</button>
                                )}
                                <button onClick={() => handleEditBalance(user)} style={{ marginLeft: "10px", cursor: "pointer" }}>Edit Balance</button>
                            </div>
                            {editingUser && editingUser.account_number === user.account_number && (
                                <div className="edit-balance-form" style={{ marginTop: '10px', display: "flex", gap: "10px" }}>
                                    <input
                                        type="number"
                                        value={newBalance}
                                        onChange={(e) => setNewBalance(e.target.value)}
                                        placeholder="New balance"
                                        style={{ padding: "5px", outline: "none" }}
                                    />
                                    <button onClick={handleSaveBalance}
                                        style={{
                                            padding: "5px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer",
                                            backgroundColor: "rgba(0,0,255,0.7)",
                                            color: "white",
                                        }}>Save</button>
                                    <button onClick={() => setEditingUser(null)}
                                        style={{
                                            padding: "5px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "blue",
                                            border: "1px solid blue"
                                        }}>Cancel</button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <button onClick={() => setOpenAllUsersModal(false)} className='AllUserCancelButton'>Close</button>
            </div>
        </div>
    );
}

export default AllUsers;
