// src/Features/Slice.js
import { createSlice } from "@reduxjs/toolkit";

const MySlice = createSlice({
  name: "user",
  initialState: {
    accountNumber:null,
    userId: null,
    userToken: null,
  },
  reducers: {
    userLogin: (state, { payload }) => {
      state.userId = payload.userId;
      state.userToken = payload.userToken;
      state.accountNumber=payload.accountNumber;
    },
    userLogout: (state) => {
      state.userInfo = null;
      state.userToken = null;
      state.accountNumber = null;
  
  }
}})

export const {
  userLogin,
  userLogout,
  
} = MySlice.actions;
export default MySlice.reducer;
