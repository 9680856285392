// import React, { useContext, useState } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import "../CSS/Login.css"
// import {useNavigate} from "react-router-dom"
// import { Context } from './Context';

// const Login = () => {
//     const navigate = useNavigate()
//     const {user,setUser}=useContext(Context)
//     const [accountNumber, setAccountNumber] = useState('');
//     const [password, setPassword] = useState('');
    


//     const handleSubmit = async (e) => {
//         e.preventDefault();
    
//         try {
//             Swal.fire({ title: 'Logging in...', allowOutsideClick: false, didOpen: () => { Swal.showLoading(); } });
    
//             const response = await axios.post('https://meritnationalbank.com/api/login.php', {
//                 account_number: accountNumber,
//                 password: password
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             });
    
//             Swal.close();
    
//             if (response.data.success) {
//                 console.log(response.data); // Check the response structure
//                 Swal.fire({
//                     title: 'Success!',
//                     text: response.data.message,
//                     icon: 'success',
//                     showConfirmButton: false,
//                     timer: 2000
//                 });
          
//                 navigate("/dashboard");
//                 setUser(response.data)
//             } else {
//                 Swal.fire('Error!', response.data.message, 'error');
//             }
//         } catch (error) {
//             Swal.fire('Error!', 'Something went wrong!', 'error');
//         }
//     };
    

//     return (
//        <div className='LoginWrap'>
//              <div className='login-container'>
//                 <h2>Account Login</h2>
//              <form onSubmit={handleSubmit}>
//             <input
//                 type="text"
//                 name="account_number"
//                 placeholder="Account Number"
//                 value={accountNumber}
//                 onChange={(e) => setAccountNumber(e.target.value)}
//                 required
//             />
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//             />
//             <button type="submit">Login</button>
//         </form>
//              </div>
//        </div>
//     );
// };

// export default Login;


import React, { useContext, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import "../CSS/Login.css";
import { useNavigate } from "react-router-dom";
import { Context } from './Context'; // Import your Context
import { userLogin } from '../Features/Slice';
import { useDispatch, useSelector } from "react-redux"

const Login = () => {
    const dispatch=useDispatch()
    const navigate = useNavigate();
    const { setUser } = useContext(Context); // Use context to manage user data
    const [accountNumber, setAccountNumber] = useState('');
    const [password, setPassword] = useState('');
    const userToken = useSelector((state)=>state.userToken)
    const userId = useSelector(state=>state.userId)
    const userAccountNumber = useSelector(state=>state.accountNumber)
        // console.log(userToken)
        console.log(userId)
        console.log(userAccountNumber)

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            Swal.fire({
                title: 'Logging in...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const response = await axios.post('https://meritnationalbank.com/api/login.php', {
                account_number: accountNumber,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            Swal.close();

            if (response.data.success) {
                console.log(response.data); // Check the response structure

                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });

                // Set user data in context
                setUser(response.data);
                const accountNumber = response.data.user.account_number
                const userId = response.data.user.user_id;
                const userToken = response.data.token;
                dispatch(userLogin({userId, userToken ,accountNumber}));
                navigate("/userdashboard");
                // Redirect to dashboard
                navigate("/dashboard");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                // text: 'Something went wrong!',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000
            });
            
        }
    };

    return (
        <div className='LoginWrap'>
            <div className='login-container'>
                <h2>Account Login</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="account_number"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
                <p>Dont have an account? <span style={{color:"blue",fontWeight:"bold",cursor:"pointer"}} onClick={()=>navigate("/verificationpage1")}>Register</span></p>
                <p><span style={{color:"blue",cursor:"pointer",fontStyle:"italic",textDecoration:"underline"}} onClick={()=>navigate("/forgotpassword")}>Forgotpassword</span></p>
            </div>
        </div>
    );
};

export default Login;
