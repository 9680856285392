import React from 'react'
import "../CSS/Hero.css"
import { useNavigate } from 'react-router-dom'
import "animate.css"
import gAmedia from "../Images/gAmedia.mp4"
import mediaB from "../Images/mediaB.mp4"

const Hero = () => {
  const navigate = useNavigate()
  return (
    <div className='HeroWrap'>
       <video controls loop autoPlay muted className='HeroMediaB'>
          <source src={mediaB} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      <video controls loop autoPlay muted className='HeroMedia'>
          <source src={gAmedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className='Hero'>
            <h1 className=''>Finance With<br/> Trust and Excellence</h1>
            <p>Exceptional financial services to help you achieve your dreams. <br/>
            With a legacy of trust and a commitment to excellence,<br/>
             we guide you towards a prosperous future. Experience the difference today!</p>
             <button onClick={()=>navigate("/verificationpage1")}>Get Started</button>
        </div>
    </div>
  )
}

export default Hero
