import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import "../CSS/Login.css";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();  // Get token from the URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmNewPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Passwords do not match',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }
    
        try {
            setLoading(true);
    
            Swal.fire({
                title: 'Submitting...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
    
            console.log("Token:", token);
            console.log("New Password:", newPassword);
    
            const response = await axios.post('https://meritnationalbank.com/api/resetpassword.php', {
                token: token,  
                password: newPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        
    
            Swal.close();
            setLoading(false);
    
            if (response.data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });
    
                navigate("/login");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.close();
    
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong!',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };
    
    return (
        <div className='LoginWrap'>
            <div className='login-container'>
                <h2>Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        name="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        name="confirmpassword"
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                    />
                    <button type="submit" disabled={loading}>Reset Password</button>
                </form>
                <p>Back to <span style={{color:"blue",cursor:"pointer",textDecoration:"underline"}} onClick={()=>navigate("/login")}>Login</span></p>
            </div>
        </div>
    );
};

export default ResetPassword;
