import React from 'react';
import '../CSS/Gallery.css';
import g1 from "../Images/g1.png"
import g2 from "../Images/g2.png"
import g3 from "../Images/g3.png"
import g4 from "../Images/g4.png"
import g5 from "../Images/g5.png"
import g6 from "../Images/g6.png"
import { useContext,useEffect } from 'react';
import { Context } from './Context';

const Gallery = () => {
  const images = [
    { src: g1, alt: 'Inspiring Image 1', caption: 'Empowering Your Financial Journey' },
    { src: g2, alt: 'Inspiring Image 2', caption: 'Building a Prosperous Future' },
    { src: g3, alt: 'Inspiring Image 3', caption: 'Trust and Excellence in Banking' },
    { src: g4, alt: 'Inspiring Image 4', caption: 'Innovative Solutions for You' },
    { src: g5, alt: 'Inspiring Image 5', caption: 'Committed to Your Success' },
    { src: g6, alt: 'Inspiring Image 6', caption: 'Your Dreams, Our Priority' }
  ];



  return (
    <div className="meritan-gallery" id="gallery">
      <h2 className="gallery-title">Gallery</h2>
      <p className="gallery-description">
        At Merit National Bank, we are dedicated to providing unparalleled financial services that empower your dreams and aspirations. Explore our gallery to see our commitment to excellence and innovation in action.
      </p>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image.src} alt={image.alt} className="gallery-image" />
            <div className="gallery-caption">{image.caption}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
