

import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context } from './Context';
import "../CSS/Header.css";
import { FaHome, FaList } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import logo from "../Images/logoM.jpeg"

const Header = () => {
    const { state, scrollStatus } = useContext(Context);
    const navigate = useNavigate();
    const [menuActive, setMenuActive] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const handleScrollToSection = (sectionId) => {
        if (sectionId === "home") {
            navigate("/");
            window.scrollTo(0, 0);
            setMenuActive(sectionId);
        } else {
            navigate('/');
            setTimeout(() => {
                scrollToSection(sectionId);
            }, 300);
        }
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
        setMenuActive(sectionId);
    };

    const [mobileMenuSwitch, setMobileMenuSwitch] = useState(false);
    const menuRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMobileMenuSwitch(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [scrollSwitch, setScrollSwitch] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollX !== 0 || window.scrollY !== 0;
            setScrollSwitch(isScrolled);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const googleTranslateElement = document.querySelector('.goog-te-combo');
        if (googleTranslateElement) {
            googleTranslateElement.value = selectedLanguage;
            googleTranslateElement.dispatchEvent(new Event('change'));
        }
    }, [selectedLanguage]);

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <div className={scrollSwitch||window.location.pathname!=="/" ? 'HeaderWrap2' : 'HeaderWrap'} id="home">
            <div className={scrollSwitch||window.location.pathname!=="/" ? 'HeaderUp2' : 'HeaderUp'}>
                <p>info@meritnationalbank.com</p>
                <p>+123456789</p>
                
                <p>Merit National Bank</p>
                <p>Monday - Friday / 8:00HRS - 16 HRS</p>
            </div>
            <div className={scrollSwitch||window.location.pathname!=="/" ? 'HeaderDown2' : 'HeaderDown'}>
                <div className='HeaderDownLeft'>
                <img src={logo} onClick={()=>handleScrollToSection("home")}  alt="logo"/>
                    {/* <h3 onClick={()=>handleScrollToSection("home")} style={{cursor:"pointer",color:`${scrollSwitch?"rgba(0,0,255,0.5)":"white"}`}}>MNB</h3> */}
                    <h2>Merit National Bank</h2>
                </div>
                <div className='HeaderDownMid'>
                    <p className={window.location.pathname === "/" && window.scrollY === 0 ? "menu1B" : "menu1A"} onClick={() => handleScrollToSection("home")}>Home</p>
                    <p className={menuActive === "about" ? "menu1A" : "menu1A"} onClick={() => handleScrollToSection("about")}>About</p>
                    <p className={menuActive === "services" ? "menu1A" : "menu1A"} onClick={() => handleScrollToSection("services")}>Services</p>
                    <p className={menuActive === "gallery" ? "menu1A" : "menu1A"} onClick={() => handleScrollToSection("gallery")}>Gallery</p>
                    {/* <p className={window.location.pathname === "/accountregister"||
                        window.location.pathname==="/verificationpage1" ? "menu1B" : "menu1A"} onClick={() => { navigate("/verificationpage1"); setMenuActive(null) }}>Get Started</p> */}
                    
                    <p className={menuActive === "contact" ? "menu1A" : "menu1A"} onClick={() => handleScrollToSection("contact")}>Contact</p>
                   
                    
                </div>
                {/* <div id="google_translate_element"></div> */}
                <div className='HeaderDownRight'>
                    {/* {window.location.pathname!=="/"&&<p className='MobileHomeButton' onClick={()=>handleScrollToSection("home")}><FaHome /> Home</p>} */}
                    <div id="google_translate_element" className='GoogleItem'></div>
                    <p className={window.location.pathname === "/accountlogin"||
                        window.location.pathname==="/verificationpage2" ? "menu1B" : "menu1A"} id="AccountLogin" onClick={() => { navigate("/verificationpage2"); setMenuActive(null) }}>Account Login</p>
                    <button onClick={()=>navigate("/verificationpage1")} >Get Started</button>
                    <p  className='ListIcon'>{mobileMenuSwitch?<p onClick={()=>setMobileMenuSwitch(false)}>X</p>:<FaList onClick={() => setMobileMenuSwitch(true)}/>}</p>
                </div>
            </div>
            {mobileMenuSwitch && <div className='MobileMenu animate__animated animate__slideInDown' ref={menuRef}>
            <p className={window.location.pathname === "/" && window.scrollY === 0 ? "menu1B" : "menu1A"} onClick={() => {handleScrollToSection("home");setMobileMenuSwitch(false)}}>Home</p>
            <div className='MobileMenuLine'></div>
                    <p className={menuActive === "about" ? "menu1A" : "menu1A"} onClick={() => {handleScrollToSection("about");setMobileMenuSwitch(false)}}>About</p>
                    <div className='MobileMenuLine'></div>
                    <p className={menuActive === "services" ? "menu1A" : "menu1A"} onClick={() => {handleScrollToSection("services");setMobileMenuSwitch(false)}}>Services</p>
                    <div className='MobileMenuLine'></div>
                    <p className={menuActive === "gallery" ? "menu1A" : "menu1A"} onClick={() => {handleScrollToSection("gallery");setMobileMenuSwitch(false)}}>Gallery</p>
                    <div className='MobileMenuLine'></div>
                    <p className={window.location.pathname === "/accountregister"||
                        window.location.pathname==="/verificationpage1" ? "menu1B" : "menu1A"} onClick={() => { navigate("/verificationpage1"); setMenuActive(null);setMobileMenuSwitch(false) }}>Get Started</p>
                    <p className={window.location.pathname === "/accountlogin"||
                        window.location.pathname==="/verificationpage2" ? "menu1B" : "menu1A"} onClick={() => { navigate("/verificationpage2"); setMenuActive(null);setMobileMenuSwitch(false) }}>Account Login</p>
                    <div className='MobileMenuLine'></div>
                    <p className={menuActive === "contact" ? "menu1A" : "menu1A"} onClick={() => {handleScrollToSection("contact");setMobileMenuSwitch(false)}}>Contact</p>
            </div>}
        </div>
    );
};

export default Header;
