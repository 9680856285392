import React, { useEffect } from 'react'
import Header from './Header'
import Hero from './Hero'
import About from './About'
import Features from './Features'
import Contact from './Contact'
import Footer from './Footer'
import Gallery from './Gallery'
import Services from './Services'
import gmc from "../Images/gmc.jpeg"
import "../CSS/LandingPage.css"
import GalleryA from './GalleryA'

const LandingPage = () => {



  return (
    <div>

      <Hero/>
      <Features/>
      <Services/>
      <div className='gmc'>
      <img src={gmc} alt="gmc"/>
      </div>
      <GalleryA/>
      <Gallery/>
      <About/>
      
      <Contact/>

    </div>
  )
}

export default LandingPage
