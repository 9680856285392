import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../CSS/Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMapClick = () => {
    window.open('https://www.google.com/maps?q=Threadneedle+Street,+London,+England,+United+Kingdom', '_blank');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !message) {
      Swal.fire('Error', 'Please fill out all required fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      // Show loading state
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we send your message.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await fetch('https://meritnationalbank.com/api/contact_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone, website, message }),
      });

      const data = await response.json();

      if (data.success) {
        Swal.fire('Success', 'Your message has been sent successfully.');
        setName('');
        setEmail('');
        setPhone('');
        setWebsite('');
        setMessage('');
      } else {
        Swal.fire('Error', data.message || 'Failed to send your message.');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred. Please try again later.');
    }

    setIsSubmitting(false);
  };

  return (
    <div className="meritan-contact-container" id="contact">
      <div className="meritan-map-container" onClick={handleMapClick}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19856.9858421375!2d-0.09510718127316268!3d51.513084088678155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761cacb54bc8d7%3A0x557c48f0a1b2a26b!2sThreadneedle%20St%2C%20London%20EC2R%208AH%2C%20UK!5e0!3m2!1sen!2sus!4v1614583425019!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="meritan-form-container">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="meritan-form-group">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="meritan-form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="meritan-form-group">
            <input
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="meritan-form-group">
            <input
              type="text"
              placeholder="Your Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="meritan-form-group">
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
