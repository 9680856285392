import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import "../CSS/Register.css";
import { useNavigate } from 'react-router-dom';

const Register = () => {


const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        address: '',
        country: '',
        state: '',
        city: '',
        zip_code: '',
        date_of_birth: '',
        phone_number: '',
        email: '',
        occupation: '',
        annual_income_range: '',
        account_type: '',
        account_currency: '',
        password: '',
        confirm_password: '',
        passport_photo: null
    });

    const [emailFirstName, setEmailFirstName] = useState("");
    const [emailAccountNumber, setEmailAccountNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailMessage, setEmailMessage] = useState("");

    

    // Handle form data changes
    const handleChange = (e) => {
        if (e.target.name === 'passport_photo') {
            const file = e.target.files[0];
            if (file && file.size > 5000 * 1024) {
                Swal.fire('Error!', 'File size must be less than 5mb');
                e.target.value = null;
                return;
            }
            if (file && !['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
                Swal.fire('Error!', 'Only JPG, JPEG, and PNG formats are allowed.');
                e.target.value = null;
                return;
            }
            setFormData({ ...formData, passport_photo: file });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };


    const updateStates = (a,b,c)=>{
        setEmailAddress(a)
        setEmailFirstName(b)
        setEmailAccountNumber(c)
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirm_password) {
            Swal.fire('Error!', 'Passwords do not match!');
            return;
        }

        const formDataObject = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObject.append(key, formData[key]);
        });

        try {
            Swal.fire({ title: 'Creating account...', allowOutsideClick: false, didOpen: () => { Swal.showLoading(); } });

            const response = await axios.post('https://meritnationalbank.com/api/register.php', formDataObject);
            Swal.close();

            if (response.data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: `${response.data.message}. Please check your email inbox or spam folder for more details`,
                });

                // Update state with response data
                // setEmailAccountNumber(response.data.account_number);
                // setEmailAddress(response.data.email);
                // setEmailFirstName(response.data.first_name);
                // updateStates(response.data.email,response.data.first_name,response.data.account_number)

                navigate("/login");
            } else {
                Swal.fire('Error!', response.data.message);
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error!', 'Something went wrong!');
        }
    };

    // Update emailMessage when the necessary data is available


    // Send email when emailMessage and emailAddress are ready
    

    // Function to send email



console.log(emailFirstName,emailAccountNumber,emailAddress,emailMessage,"hey 5")

useEffect(() => {
    if (emailAccountNumber && emailAddress && emailFirstName) {
        console.log("hey 2")
        setEmailMessage(`Hi ${emailFirstName},\n\nYour account has been created successfully.\n\nYour account number is ${emailAccountNumber}.\nPlease log in at: https://meritnationalbank.com/login\n\nBest regards,\nMerit National Bank`);
    }
}, [emailAccountNumber, emailAddress, emailFirstName]);

useEffect(() => {

    if (emailMessage && emailAddress) {
        console.log("hey 3")
        sendEmail();
    }
}, [emailMessage, emailAddress]);


    const sendEmail = async () => {
        console.log("hey 1")
        try {
            Swal.fire({
                title: 'Sending...',
                text: 'Your email is being sent',
                icon: 'info',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
            });

            const response = await axios.post('http://localhost:5000/send-email', {
                emailAddress,
                emailMessage,
            });

            Swal.fire({
                title: 'Success!',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: error.response?.data?.error || 'An unexpected error occurred',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };
    return (
        <div className="RegisterWrap">
            <div className="register-body">
                <div className='register-container'>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="register-form">
                        <h2 className="register-title">Create Account</h2>

                        <div className='registerSection'>
                            <input type="text" name="first_name" placeholder="First Name" onChange={handleChange} required className="register-input" />
                            <input type="text" name="middle_name" placeholder="Middle Name" onChange={handleChange} className="register-input" />
                            <input type="text" name="last_name" placeholder="Last Name" onChange={handleChange} required className="register-input" />
                        </div>

                        <input type="text" name="address" placeholder="Address" onChange={handleChange} required className="register-input" />

                        <div className="registerSection">
                            <input type="text" name="country" placeholder="Country" onChange={handleChange} required className="register-input" />
                            <input type="text" name="state" placeholder="State/Province" onChange={handleChange} required className="register-input" />
                            <input type="text" name="city" placeholder="City" onChange={handleChange} required className="register-input" />
                        </div>

                        <div className="registerSection">
                            <input type="text" name="zip_code" placeholder="Zip Code" onChange={handleChange} required className="register-input" />
                            <div className='DateWrap'>
                                <p style={{ fontSize: "small", color: "rgba(0,0,255,0.7)", fontWeight: "bold" }}>Date of Birth:</p>
                                <input type="date" name="date_of_birth" placeholder="Date of Birth" onChange={handleChange} required className="register-input" />
                            </div>
                        </div>

                        <div className="registerSection">
                            <input type="text" name="phone_number" placeholder="Phone Number" onChange={handleChange} required className="register-input" />
                            <input type="email" name="email" placeholder="Email Address" onChange={handleChange} required className="register-input" />
                            <input type="text" name="occupation" placeholder="Occupation" onChange={handleChange} required className="register-input" />
                        </div>

                        <div className="registerSection">
                            <select name="annual_income_range" onChange={handleChange} required className="register-select">
                                <option value="">Select Annual Income Range</option>
                                <option value="below_25k">Below $25,000</option>
                                <option value="25k_50k">$25,000 - $50,000</option>
                                <option value="50k_100k">$50,000 - $100,000</option>
                                <option value="above_100k">Above $100,000</option>
                            </select>
                            <select name="account_type" onChange={handleChange} required className="register-select">
                                <option value="">Please select Account Type</option>
                                <option value="Savings">Savings</option>
                                <option value="Current">Current</option>
                                <option value="Fixed Deposit">Fixed Deposit</option>
                            </select>
                            <select name="account_currency" onChange={handleChange} required className="register-select">
                                <option value="">Please select Account Currency</option>
                                <option value="USD">USD</option>
                                <option value="GBP">GBP</option>
                                <option value="Kuwait Dinar">Kuwait Dinar</option>
                                <option value="Chinese Yuan">Chinese Yuan</option>
                                <option value="Japanese Yen">Japanese Yen</option>
                                <option value="Euro">Euro</option>
                                <option value="NZD">NZD</option>
                            </select>
                        </div>

                        <div className="registerSection">
                            <input type="password" name="password" placeholder="Password" onChange={handleChange} required className="register-input" />
                            <input type="password" name="confirm_password" placeholder="Confirm Password" onChange={handleChange} required className="register-input" />
                            <div className="DateWrap">
                                <p style={{ fontSize: "small", color: "rgba(0,0,255,0.7)", fontWeight: "bold" }}>Passport Photo:(Maximum of 5MB)</p>
                                <input type="file" name="passport_photo" accept="image/jpeg,image/png,image/jpg" onChange={handleChange} required className="register-file-input" />
                            </div>
                        </div>

                        <button type="submit" className="register-button">Register</button>
                    </form>
                    <p style={{ marginTop: "20px" }}>Already have an account? <span style={{ color: "rgba(0,0,255,0.7)", fontWeight: "bold", cursor: "pointer" }} onClick={() => navigate("/verificationpage2")}>Login</span></p>
                </div>
            </div>
        </div>
    );
};

export default Register;
