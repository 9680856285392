import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import '../CSS/ProfileEditModal.css';
import { Context } from './Context';

const ProfileEditModal = () => {
    const { user, setOpenProfileEditModal, setUser } = useContext(Context);
    const [newPhoneNumber, setNewPhoneNumber] = useState(user?.user?.phone_number || '');
    const [newAddress, setNewAddress] = useState(user?.user?.address || '');
    const [newProfileImage, setNewProfileImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size
            if (file.size > 5000 * 1024) { // 500KB
                Swal.fire('Error', 'The image size should be a maximum of 5MB.');
                e.target.value = null;
                return;
            }

            // Check file type
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                Swal.fire('Error', 'Please upload an image in JPG, JPEG, or PNG format.');
                e.target.value = null;
                return;
            }

            setNewProfileImage(file);
        }
    };

    const handleSubmit = () => {
        Swal.fire({
            text: "Do you want to save these changes?",
            showCancelButton: true,
            confirmButtonText: 'Yes, save it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                // Show loading spinner
                Swal.fire({
                    title: 'Saving changes...',
                    text: 'Please wait while we update your profile.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                // Prepare form data
                const formData = new FormData();
                formData.append('user_id', user?.user?.user_id); // Include user ID
                formData.append('phone_number', newPhoneNumber);
                formData.append('address', newAddress);
                if (newProfileImage) {
                    formData.append('profile_image', newProfileImage);
                }

                // Submit data to the backend
                fetch('https://meritnationalbank.com/api/update_profile.php', {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        Swal.close(); // Close loading spinner
                        if (data.success) {
                            Swal.fire('Saved!', data.message);
                            setOpenProfileEditModal(false); // Close modal after success
                            window.location.reload();
                        } else {
                            Swal.fire('Error', data.message || 'Failed to update profile.');
                        }
                    })
                    .catch((error) => {
                        Swal.close(); // Close loading spinner
                        Swal.fire('Error', 'Something went wrong. Please try again later.');
                    });
            }
        });
    };

    return (
        <div className='ProfileEditModalWrap'>
            <div className="profile-edit-modal-container">
                <h2>Edit Profile Information</h2>
                <div className="profile-edit-modal-group">
                    <label>Phone Number:</label>
                    <input
                        type="text"
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e.target.value)}
                    />
                </div>
                <div className="profile-edit-modal-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        value={newAddress}
                        onChange={(e) => setNewAddress(e.target.value)}
                    />
                </div>
                <div className="profile-edit-modal-group">
                    <label>Profile Image: (jpg, jpeg, png, Max file size - 5MB)</label>
                    <input type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleImageChange} />
                </div>
                <div className="profile-edit-modal-buttons">
                    <button onClick={() => setOpenProfileEditModal(false)}>Cancel</button>
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default ProfileEditModal;
