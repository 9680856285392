
import React from 'react'
import Header from './components/Header'
import {BrowserRouter, Routes, Route, Router} from "react-router-dom"
import LandingPage from './components/LandingPage'
import Footer from './components/Footer'
import VerificationPage1 from './components/VerificationPage1'
import VerificationPage2 from './components/VerificationPage2'
import ScrollToTop from './components/ScrollToTop'
import AccountRegister from './components/AccountRegister'
import Register from './components/Register'
import Login from './components/Login'
import Dashboard from './components/UserDashboard'
import "./App.css"
import ManualCredit from './components/ManualCredit'
import PrivateDashboard from './components/PrivateDashboard'
import wp from "./Images/whatsapplogo.png"
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'

const App = () => {
  return (
    <BrowserRouter>
    <ScrollToTop/>
    <Header/>
      <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/verificationpage1" element={<VerificationPage1/>}/>
        <Route path="/verificationpage2" element={<VerificationPage2/>}/>
        <Route path="/accountregister" element={<Register/>}/>
        <Route path = "/login" element={<Login/>}/>
        <Route path = "/forgotpassword" element={<ForgotPassword/>}/>
        <Route path='resetpassword/:token' element={<ResetPassword/>}/> 
        <Route path="/dashboard" element={<PrivateDashboard />}>
          <Route path="" element={<Dashboard />} />
      </Route>

     
      </Routes>
      <a ><img src={wp} alt="logo" className="WhatsAppIcon" onClick={()=>window.open("https://wa.me/447440788045","_blank")}/></a>

      <Footer/>

    </BrowserRouter>
  )
}

export default App
