import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import "../CSS/DepositUi.css"; // Updated the path to match the current location
import { Context } from './Context';
import { useSelector } from 'react-redux';

const DepositUi = () => {
    const { setOpenDepositModal } = useContext(Context);
    const [transactionId, setTransactionId] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState(''); // New account name state
    const [isSubmitting, setIsSubmitting] = useState(false);
    const walletAddress = "3M21tQZ4NXrSrh6qow7tjtDycrkA5EmXvR";
    const UserAccountNumber = useSelector(state => state.accountNumber);

    const handleSubmit = async () => {
        if (!transactionId || !accountNumber || !accountName) {
            Swal.fire('Error!', 'Please enter account name, account number, and transaction ID.');
            return;
        } else if (accountNumber !== UserAccountNumber) {
            Swal.fire('Error!', 'Please enter your valid account number');
            return;
        }

        setIsSubmitting(true);

        // Show loading spinner
        Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await fetch('https://meritnationalbank.com/api/notify_deposit.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    account_name: accountName, // Include account name
                    account_number: accountNumber,
                    transaction_id: transactionId,
                }),
            });

            if (response.ok) {
                Swal.fire({
                    text: 'Your deposit is being verified. It will reflect in your account once verified; you shall also receive an email; please check your inbox or spam folder. Thanks'
                });
                setTransactionId('');
                setAccountNumber('');
                setAccountName(''); // Clear account name after submission
                setOpenDepositModal(false);
            } else {
                Swal.fire('Error!', 'Failed to submit your deposit details.');
            }
        } catch (error) {
            Swal.fire('Error!', 'Something went wrong. Please try again.');
        }

        setIsSubmitting(false);
    };

    return (
        <div className='DepositUiWrap'>
            <div className="DepositUi">
                <h2>Deposit Funds</h2>
                <p>Send VAI to the wallet address below and when done, enter your account name, your account number, and the transaction ID from your VAI deposit, then click "submit".</p>
                <br />
                <p>VAI wallet Address:</p>
                <p className="WalletAddress">{walletAddress}</p>
                <p style={{ fontStyle: "italic" }}>Please ensure that you are sending VAI</p>

                <div className="Modal">
                    <h2>Enter Deposit Details</h2>
                    <input
                        type="text"
                        placeholder="Your Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        disabled={isSubmitting}
                    />
                    <input
                        type="text"
                        placeholder="Your Account Name" // New account name input
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        disabled={isSubmitting}
                    />
                    <input
                        type="text"
                        placeholder="VAI Transaction ID"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        disabled={isSubmitting}
                    />
                    <button onClick={() => setOpenDepositModal(false)} disabled={isSubmitting} style={{ color: "#0056b3", backgroundColor: "white", border: "1px solid #0056b3" }}>Cancel</button>
                    <button onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DepositUi;
