import React, { useState } from 'react';
import { FaCreditCard, FaDollarSign, FaMoneyBillTransfer, FaMoneyCheckDollar } from 'react-icons/fa6';
import "../CSS/Services.css";
import bank from "../Images/bank.jpeg";

const Services = () => {
  // State to track the hovered service
  const [hoveredService, setHoveredService] = useState(null);

  const handleMouseOver = (service) => {
    setHoveredService(service);
  };

  const handleMouseLeave = () => {
    setHoveredService(null);
  };

  return (
    <div className='ServicesWrap' id="services">
      <h2>OUR EXPERTISE AND SOLUTIONS</h2>
      <div className='ServicesContainer'>
        <img src={bank} alt="services" />
        <div className='ServicesRight'>
          <div
            className={hoveredService === 'service1' ? 'Service2 animate__animated animate__bounce' : 'Service animate__animated animate__bounce'}
            onMouseOver={() => handleMouseOver('service1')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredService === 'service1' ? (
              <>
                <span><FaDollarSign/></span>
                <h3>Personal and Business Banking</h3>
                <p>Our banking services offer tailored solutions for both personal and business needs, providing convenience, security, and support for your financial goals.</p>
              </>
            ) : (
              <>
                <span><FaDollarSign/></span>
                <h3>Personal and Business Banking</h3>
                <p>Our banking services offer tailored solutions for both personal and business needs, providing convenience, security, and support for your financial goals.</p>
              </>
            )}
          </div>

          <div
            className={hoveredService === 'service2' ? 'Service2 animate__animated animate__bounce' : 'Service animate__animated animate__bounce'}
            onMouseOver={() => handleMouseOver('service2')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredService === 'service2' ? (
              <>
                <span><FaMoneyBillTransfer/></span>
                <h3>Loans</h3>
                <p>We offer a variety of loans with competitive rates and flexible terms to help you buy a home, finance education, or grow your business.</p>
              </>
            ) : (
              <>
                <span><FaMoneyBillTransfer/></span>
                <h3>Loans</h3>
                <p>We offer a variety of loans with competitive rates and flexible terms to help you buy a home, finance education, or grow your business.</p>
              </>
            )}
          </div>

          <div
            className={hoveredService === 'service3' ? 'Service2 animate__animated animate__bounce' : 'Service animate__animated animate__bounce'}
            onMouseOver={() => handleMouseOver('service3')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredService === 'service3' ? (
              <>
                <span><FaCreditCard/></span>
                <h3>Credit Cards</h3>
                <p>Our credit cards provide convenience, security, and rewards, including cashback, travel benefits, and low-interest rates.</p>
              </>
            ) : (
              <>
                <span><FaCreditCard/></span>
                <h3>Credit Cards</h3>
                <p>Our credit cards provide convenience, security, and rewards, including cashback, travel benefits, and low-interest rates.</p>
              </>
            )}
          </div>

          <div
            className={hoveredService === 'service4' ? 'Service2 animate__animated animate__bounce' : 'Service animate__animated animate__bounce'}
            onMouseOver={() => handleMouseOver('service4')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredService === 'service4' ? (
              <>
                <span><FaMoneyCheckDollar/></span>
                <h3>Investment Services</h3>
                <p>Our investment services help you grow and protect your wealth with expert financial advice and diverse portfolio options.</p>
              </>
            ) : (
              <>
                <span><FaMoneyCheckDollar/></span>
                <h3>Investment Services</h3>
                <p>Our investment services help you grow and protect your wealth with expert financial advice and diverse portfolio options.</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
