import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import "../CSS/Login.css";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return;

        if (!validateEmail(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address.',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }

        try {
            setLoading(true);

            Swal.fire({
                title: 'Sending request...',
                text: 'Please wait a moment.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const response = await axios.post('https://meritnationalbank.com/api/forgotpassword.php', {
                email: email,  // Sending the email from the state
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            Swal.close();
            setLoading(false);

            if (response.data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: `${response.data.message} Please check your email inbox or spam folder` ,
                    icon: 'success',
                    showConfirmButton: true,
                    
                });
                setEmail("");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.close();
            
            console.error('Error occurred:', error);

            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong. Please try again later.',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    return (
        <div className='LoginWrap'>
            <div className='login-container'>
                <h2>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit" disabled={loading}>Submit</button>
                </form>
                <p>Back to <span style={{color:"blue",cursor:"pointer",textDecoration:"underline"}} onClick={()=>navigate("/login")}>Login</span></p>
            </div>
        </div>
    );
};

export default ForgotPassword;
