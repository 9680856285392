import React, { useContext, useState, useEffect } from 'react';
import '../CSS/UserDashboard.css';
import { Context } from './Context';
import { FaDesktop, FaEye, FaEyeSlash, FaHome, FaLaptop, FaMailBulk, FaPhone, FaSuitcase, FaUserGraduate } from "react-icons/fa";
import axios from 'axios';
import Swal from 'sweetalert2';
import DepositUi from './DepositUi';
import ProfileEditModal from './ProfileEditModal';
import ManualCredit from './ManualCredit';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../Features/Slice';
import AllUsers from './AllUsers';

const Dashboard = () => {
    const dispatch = useDispatch()
    const { user ,setUser, openDepositModal, setOpenDepositModal,openProfileEditModal,setOpenProfileEditModal,
        openCreditUserBalanceUi,setOpenCreditUserBalanceUi,openAllUsersModal,setOpenAllUsersModal
    } = useContext(Context);
    const [balanceShow, setBalanceShow] = useState(true);
    const [accountName, setAccountName] = useState('');
    const [receiverAccountNumber, setReceiverAccountNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');  // Optional description
    const [transactions, setTransactions] = useState([]);
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const userId = useSelector(state=>state.userId)
    const userAccountNumber = useSelector(state=>state.accountNumber);
    


    const fetchTransactions = async () => {
        const loadingAlert =  Swal.fire({ text: 'Fetching your transactions...', allowOutsideClick: false, didOpen: () => { Swal.showLoading(); } });

        try {
            const response = await fetch('https://meritnationalbank.com/api/fetch_transactions.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    account_number: userAccountNumber
                    // account_number: user?.user?.account_number // Fetch transactions for this account
                })
            });

            const data = await response.json();

            if (data.success) {
                setTransactions(data.transactions);
            } else {
                Swal.fire({text: data.message, icon:'error',showConfirmButton:false,timer:1000});
            }
        } catch (error) {
            Swal.fire('Error!', 'Failed to fetch transactions.', 'error');
        }finally{
            loadingAlert.close();
        }
    };
    useEffect(() => {
        if(user){
            fetchTransactions();
        }
    }, [user?.user?.account_number]);


    useEffect(()=>{
        if(receiverAccountNumber.length===10){
            fetchAccountName()
        }
    },[receiverAccountNumber,setReceiverAccountNumber])

    const fetchAccountName = async () => {
        const loadingAlert =  Swal.fire({ title: 'Fetching User...', allowOutsideClick: false, didOpen: () => { Swal.showLoading(); } });

        const response = await fetch('https://meritnationalbank.com/api/fetch_user.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                account_number: receiverAccountNumber // Make sure this variable is correctly set
            })
        });

        const data = await response.json();

        if (data.success) {
            setAccountName(data.user_name);
            loadingAlert.close();
        } else {
            Swal.fire({text:data.message || 'Failed to fetch account name please try again', timer:2000, showConfirmButton:false})
            
        }
    };

    const handleTransfer = async () => {
        if (!amount || isNaN(amount) || amount <= 0) {
            Swal.fire('Error!', 'Please enter a valid amount.');
            return;
        }

        const loadingAlert =  Swal.fire({ title: 'Transfer in progress...', allowOutsideClick: false, didOpen: () => { Swal.showLoading(); } });


        try {
            const response = await fetch('https://meritnationalbank.com/api/transfer.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sender_account: user?.user?.account_number,  // Sender's account number
                    receiver_account: receiverAccountNumber,    // Receiver's account number
                    amount: amount,                      // Amount to transfer
                    description: description          // Optional description
                }),
            });

            const data = await response.json();

            if (data.success) {
                Swal.fire({
                    // icon:"success",
                    title: 'Success!',
                    text: data.message,
                    // showConfirmButton: false,
                    // timer: 2000
                });
                setOpenAccountModal(false);
                setAmount("")
                setReceiverAccountNumber("")
                setDescription("")
                setAccountName("")
                window.location.reload();
                // Optionally, refresh user data to reflect updated balance and transactions
                // await fetchTransactions(); // Refresh transactions
            } else {
                Swal.fire('Error!', data.message,);
            }
        } catch (error) {
            Swal.fire('Error!', 'Something went wrong during the transfer.');
        }finally{
            loadingAlert.close()
        }
    };


   

    
    //fetching details again
    const fetchUserDetails = () => {
        return fetch('https://meritnationalbank.com/api/get_user_details.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId}),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log(data)
                setUser(data)
            } else {
                Swal.fire('Error', data.message || 'Failed to fetch user details.');
                return null;
            }
        })
        .catch((error) => {
            Swal.fire('Error', 'Something went wrong. Please try again later.');
            return null;
        });
    };
    
    useEffect(()=>{
        fetchUserDetails()
    },[])

    useEffect(()=>{
        if(user?.user?.suspended==="1"){
            Swal.fire({icon:"warning",
                text:"Your account has been suspended, Please contact our support team.",
                showConfirmButton:true,
                confirmButtonText:"logout",
                allowOutsideClick:false}).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(userLogout())
                    }
        })
    }
},[user])
    

    return (
        <div className='DashboardWrap'>
            <div className="dashboardContainer">
                <div className="dashboard">
                    {user?.user?.email==="support@meritnationalbank.com"&&<div className='dashboardAdmin'>
                    <h3 style={{display:'flex',justifyContent:"center",alignItems:"center",gap:"10px"}}><FaLaptop/> Admin Area</h3>
                    <button style={{marginTop:"10px",color:"blue"}} onClick={()=>setOpenCreditUserBalanceUi(true)}>Credit User account balance</button>
                    <button style={{marginTop:"10px",color:"blue"}} onClick={()=>setOpenAllUsersModal(true)}>Manage Users</button>
                    
                    
                    </div>}

                    <h1>Welcome,<br /> {user?.user?.first_name} {user?.user?.last_name}</h1>
                    
                    {user?.user?.passport_photo && (
                        <img src={user?.user?.passport_photo} alt="Passport" className='passport-photo' />
                    )}
                    {/* <p>{user?.user?.suspended}</p> */}
                    <p><span style={{ color: "blue" }}><FaMailBulk /> Email:</span> {user?.user?.email} {user?.user?.suspended}</p>
                    <p><span style={{ color: "blue" }}><FaPhone /> Phone:</span> {user?.user?.phone_number}</p>
                    <p><span style={{ color: "blue" }}><FaHome /> Address:</span> {user?.user?.address}</p>
                    <p><span style={{ color: "blue" }}><FaSuitcase /> Account Number:</span> {user?.user?.account_number}</p>
                    <button style={{marginTop:"0px"}} onClick={()=>setOpenProfileEditModal(true)}>Edit Profile Information</button>
                    <h2>Account Balance</h2>
                    <div className='BalanceWrap'>
                        <div className='Balance'>
                            {balanceShow
                                ? <p>{user?.account?.currency}: {user?.account?.balance}</p>
                                : <p style={{ fontWeight: "bold" }}>********************</p>}
                        </div>
                        {balanceShow
                            ? <FaEyeSlash className='Eye' onClick={() => setBalanceShow(false)} />
                            : <FaEye className='Eye' onClick={() => setBalanceShow(true)} />}
                    </div>
                    
                    <button onClick={() => dispatch(userLogout())} className='LogoutA'>Logout</button>

                </div>
                <div className='dashboardRight'>
                    <div className='TransferDepositWrap'>
                    <button className='TransferButton' onClick={() => setOpenAccountModal(true)}>Make Transfer</button>
                    
                    <button className='TransferButton' onClick={() => setOpenDepositModal(true)}>Make Deposit</button>
                    </div>
                    
                    <div className='TransactionHistory'>
                        <h2>Transaction History</h2>
                        <ul className='Transactions'>
                            {transactions.length > 0 ? (
                                transactions.map((transaction, index) => (
                                    <li key={index} className='Transaction' >
                                        <p>Date: {transaction.date}  </p>
                                        <p>Amount: {user?.account?.currency} {transaction.amount} <span style={{fontWeight:"bold",color:transaction.sender_account===user?.user?.account_number?"rgba(255,0,0,0.6)" : "green"}}>{transaction.sender_account===user?.user?.account_number?"Debit" : "Credit"}</span></p>
                                        <p>Sender: {transaction.sender_name} ({transaction.sender_account})</p>
                                        <p>Receiver: {transaction.receiver_name} ({transaction.receiver_account})</p>
                                        <p>Description: {transaction.description || 'No description'}</p>
                                    </li>
                                ))
                            ) : (
                                <p>You have no transactions at the moment.</p>
                            )}
                            {/* <button>View more transactions</button> */}
                        </ul> 
                    </div>
                </div>
                
                <button onClick={() => dispatch(userLogout())} className='LogoutB'>Logout</button>
            </div>

         
            {openAccountModal && (
                <div className='TransferModalWrap'>
                    <div className='TransferModal'>
                    <input
                        placeholder='Account number'
                        value={receiverAccountNumber}
                        onChange={(e) => setReceiverAccountNumber(e.target.value)}
                    />
                    <p>{accountName}</p>
                    <input
                        placeholder='Amount'
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <input
                        placeholder='Description (optional)'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className='TransferModalButtonWrap'>
                    <button onClick={() => {
                        setOpenAccountModal(false);
                        setAmount("")
                        setReceiverAccountNumber("")
                        setDescription("")
                        setAccountName("")
                        }} style={{color:"blue",backgroundColor:"white"}}>Cancel</button>
                    <button onClick={handleTransfer}>Transfer</button>

                    </div>

                    
                </div>
                </div>

            )}
            {openCreditUserBalanceUi&&<ManualCredit/>}
            {openProfileEditModal&&<ProfileEditModal/>}
            {openDepositModal&&<DepositUi/>}
            {openAllUsersModal&&<AllUsers/>}
        </div>
    );
};

export default Dashboard;
