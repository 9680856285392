import React from 'react'
import "../CSS/GalleryA.css"
import gApic1 from "../Images/gApic1.jpeg"
import gApic2 from "../Images/gApic2.png"
import gApic3 from "../Images/gApic3.png"
import gApic4 from "../Images/gApic4.jpeg"
import gAmedia from "../Images/gAmedia.mp4"



const GalleryA = () => {
  return (
    <div className='GalleryAWrap'>
        <div className='GalleryA1'>
            <img src={gApic4} alt="gApic1"/>
            <p>The current president of the European Central Bank (ECB) is Christine Lagarde, previously the chair and managing director of the International Monetary Fund</p>
            {/* <video controls loop autoPlay muted>
          <source src={gAmedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        </div>
       
        <div className="gallery-container">
      
      <div className="media-item fade-in">
        <img src={gApic2} alt="Image 2" />
      </div>
      <div className="media-item fade-in">
        <img src={gApic1} alt="Image 1" />
      </div>
      <div className="media-item fade-in">
        <img src={gApic3} alt="Image 3" />
      </div>
     
    </div>
    {/* <video controls loop autoPlay muted className='videoMobile'>
          <source src={gAmedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
    </div>
    
  )
}

export default GalleryA
