import React from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import '../CSS/Footer.css';
import logo from "../Images/logoM.jpeg"

const Footer = () => {
    const navigate = useNavigate()

    const handleScrollToSection = (sectionId) => {
        if(sectionId==="home"){
          navigate("/")
          window.scrollTo(0,0)

        }else{
          navigate('/');
        setTimeout(() => {
          scrollToSection(sectionId);
        }, 300);
        }
      };


    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

  return (
    <footer className="meritan-footer">
      <div className="footer-section about">
        <img src={logo} alt="logo"/>
        <h2 className="footer-logo">Merit National Bank</h2>
        <p style={{fontSize:"0.9rem"}}>
          At Merit National Bank, we are committed to providing unparalleled financial services that empower your dreams and aspirations. With a legacy of trust and a focus on excellence, we guide you towards a prosperous future.
        </p>
      </div>
      <div className="footer-section services">
        <h3>Our Services</h3>
        <ul>
          <li style={{fontSize:"0.9rem"}}>Personal Banking</li>
          <li style={{fontSize:"0.9rem"}}>Business Banking</li>
          <li style={{fontSize:"0.9rem"}}>Loans</li>
          <li style={{fontSize:"0.9rem"}}>Credit Cards</li>
          <li style={{fontSize:"0.9rem"}}>Investment Services</li>
        </ul>
      </div>
      <div className="footer-section quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>handleScrollToSection("home")}>Home</li>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>handleScrollToSection("about")}>About</li>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>handleScrollToSection("services")}>Services</li>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>handleScrollToSection("gallery")}>Gallery</li>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>navigate("/openaccount")}>Open Account</li>
          <li style={{fontSize:"0.9rem", cursor:"pointer"}} onClick={()=>handleScrollToSection("contact")}>Contact</li>
        </ul>
      </div>
      <div className="footer-section social-media">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <FaWhatsapp style={{cursor:"pointer"}} onClick={()=>window.open("https://whatsapp.com","_blank")}/>
          <FaFacebook style={{cursor:"pointer"}} onClick={()=>window.open("https://facebook.com","_blank")}/>
          <FaTwitter style={{cursor:"pointer"}} onClick={()=>window.open("https://twitter.com","_blank")}/>
          <FaInstagram style={{cursor:"pointer"}} onClick={()=>window.open("https://instagram.com","_blank")}/>
        </div>
      </div>
      <div className="footer-section contact-info">
        <h3>Contact Us</h3>
        <p style={{fontSize:"0.9rem"}}>Headquarters: Threadneedle Street, London, England, United Kingdom. Director: Andrew Bailey.</p>
        <br/>
        <p style={{fontSize:"0.9rem"}}>Russian Headquarters: Neglinnaya St, 12, Moskva, Russia, 107031. Director: Elvira Sakhipzadovna Nabiullina.</p>
        <br/>
        <p style={{fontSize:"0.9rem"}}>Phone: +44 7440788045</p>
        <p style={{fontSize:"0.9rem"}}>Email: support@meritnationalbank.com</p>
        <p style={{fontSize:"0.9rem"}}>Email: Meritnationallimited@gmail.com</p>
        <p style={{fontSize:"0.9rem"}}>Monday - Friday: 8:00HRS - 16:00HRS</p>
      </div>
     
    </footer>
  );
};

export default Footer;
