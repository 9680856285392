import React, { useContext } from 'react'
import { Context } from './Context'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateDashboard = () => {
    const {user}=useContext(Context)
    const userToken = useSelector(state=>state.userToken)
  return (
    userToken?<Outlet/>:<Navigate to="/login"/>
  )
}

export default PrivateDashboard
